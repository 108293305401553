import './AnketaProgression.scss';


import { useParams, useLocation, useHistory } from 'react-router-dom';
import { anketaListActiveQuestionChange } from '../../actions';
import { Link } from 'react-router-dom';

interface AnketaProgressionProps {
  questionsCount: number;
  currentQuestionNumber: number;
  anketaQuestionsAnswers: any;
}

export const AnketaProgression: React.FC<AnketaProgressionProps> = ({ questionsCount, currentQuestionNumber, anketaQuestionsAnswers }) => {
  const { anketaID, anketaAnswersID } = useParams<{ anketaID: string; anketaAnswersID: string }>();
  const location = useLocation();
  const history = useHistory();

  const prograssionPoints = () => {
    let prograssionPointsArray: object[] = [];
    let idx = 0;
    anketaQuestionsAnswers.forEach((question, index) => {
      index === currentQuestionNumber &&
        prograssionPointsArray.push(
          <div
            className={`progressionPoint hoverPossible 
              ${location.pathname !== `/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/` ? ' active' : ''}   
              ${question.rate !== null ? ' rated' : ''}   
            `}
            key={index}
          >
            {index + 1}
          </div>
        );
      index !== currentQuestionNumber &&
        prograssionPointsArray.push(
          <Link
            className={`progressionPoint hoverPossible ${question.rate !== null ? 'rated' : ''}`}
            to={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${index + 1}/`}
            key={index}
          >
            {index + 1}
          </Link>
        );
    });

    prograssionPointsArray.push(
      <div
        className={`progressionPoint hoverPossible${location.pathname === `/expert/review/anketa/${anketaID}/${anketaAnswersID}/review/` ? ' active' : ''}`}
        onClick={() => {
          history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/review/`);
        }}
        key={'progressionPointReview'}
      ></div>
    );

    prograssionPointsArray.push(
      <div
        className={`progressionPoint hoverPossible${location.pathname === `/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/` ? ' active' : ''}`}
        onClick={() => {
          history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/`);
        }}
        key={'progressionPointLast'}
      ></div>
    );
    return prograssionPointsArray;
  };

  return (
    <section className='anketaProgression is_expert'>
      {anketaQuestionsAnswers && (
        <>
          {prograssionPoints()}
          <Link to={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/intro/`}>Список вопросов</Link>
        </>
      )}
    </section>
  );
};
