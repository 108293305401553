import { Route, Switch, useLocation } from 'react-router-dom';

import AdminAnketaEvaluation from './pages/P_5.4_Admin_AnketaEvaluation';
import AdminClient from './pages/P_5.2_Admin_Client';
import AdminClientAnketas from './pages/P_5.3_Admin_ClientAnketas';
import AdminClientForm from './pages/P_5.3_Admin_ClientForm';
import AdminClientMembers from './pages/P_5.5_Admin_ClientMembers';
import AdminClientReportHTML from './pages/P_5.12.1_Admin_ClientReportHTML';
import AdminClientReportPDF from './pages/P_5.12.2_Admin_ClientReportPDF';
import AdminClientStream from './pages/P_5.2.5_Admin_ClientStream';
import AdminClientTemplates from './pages/P_5.6_Admin_ClientTemplates';
import AdminClients from './pages/P_5.1_Admin_Clients';
import AdminExperts from './pages/P_5.8_Admin_Experts';
import AdminMemberReportHTML from './pages/P_5.10.1_Admin_MemberReportHTML';
import AdminMemberReportPDF from './pages/P_5.10.2_Admin_MemberReportPDF';
import AdminReports from './pages/P_5.13_Admin_Reports';
import AdminTemplateCopy from './pages/P_5.7.5_Admin_TemplateCopy';
import AdminTemplateForm from './pages/P_5.7_Admin_TemplateForm';
import AdminTribeReportHTML from './pages/P_5.11.1_Admin_TribeReportHTML';
import AdminTribeReportPDF from './pages/P_5.11.2_Admin_TribeReportPDF';
import { AnimatePresence } from 'framer-motion';
import AnimatedRedirect from './components/AnimatedRedirect';
import EmailConfirm from './pages/P_2.3_EmailConfirm';
import Error500 from './pages/P_0.2_Error500';
import ExpertAnketaDescription from './pages/P_4.3_Expert_AnketaDescription';
import ExpertAnketaGeneralEvaluation from './pages/P_4.6_Expert_AnketaGeneralEvaluation';
import ExpertAnketaQuestionsEvaluation from './pages/P_4.5_Expert_AnketaQuestionsEvaluation';
import ExpertAnketaTableOfContents from './pages/P_4.4_Expert_AnketaTableOfContents';
import ExpertRespondents from './pages/P_4.2_Expert_Respondents';
import ExpertSchedule from './pages/P_4.1_Expert_Schedule';
import { Header } from './components/Header/Header';
import Login from './pages/P_1.1_Login';
import MemberAnketaDescription from './pages/P_3.2_Member_AnketaDescription';
import MemberAnketaNoInterviewComplete from './pages/P_3.3.5_Member_AnketaNoInterviewComplete';
import MemberAnketaQuestions from './pages/P_3.3_Member_AnketaQuestions';
import MemberInquirer from './pages/P_3.1.5_Member_Inquirer';
import MemberInterview from './pages/P_3.4_Member_Interview';
import MemberIntro from './pages/P_3.1_Member_Intro';
import NotFound404 from './pages/P_0.1_NotFound404';
import PasswordRecoverForm from './pages/P_1.2_PasswordRecovery';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Profile from './pages/P_1.3_Profile';
import { ProfileAnketas } from './pages/P_1.4_ProfileAnketas/ProfileAnketas';
import RegistrationExpert from './pages/P_2.1_RegistrationExpert';
import RegistrationMember from './pages/P_2.2_RegistrationMember';
import { SupportModal } from './components/SupportModal/SupportModal';
import _ from 'lodash';
import ExpertMemberReportPDF from "./pages/P_4.7_Expert_MemberReportPDF";
import ExpertAnketaReview from './pages/P_4.7_Expert_AnketaReview';

function RouterComponent() {
  const location = useLocation();

  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          {/* // * All users */}
          <Route exact path='/' component={Login} />
          <Route exact path='/password_recover/' component={PasswordRecoverForm} />
          <Route exact path='/password_recover/:key/' component={PasswordRecoverForm} />
          <Route exact path='/profile/' component={Profile} />
          <Route exact path='/registration/expert/:key/' component={RegistrationExpert} />
          <Route exact path='/registration/' component={RegistrationMember} />
          <Route exact path='/email_confirm/:key/' component={EmailConfirm} />
          <Route exact path='/email_confirm/' component={EmailConfirm} />
          // * Member
          <PrivateRoute accessibleFor='member' exact path='/client/anketas/' component={ProfileAnketas} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/:anketaID/intro/' component={MemberIntro} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/:anketaID/inquirer/' component={MemberInquirer} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/:anketaID/preview/' component={MemberAnketaDescription} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/:anketaID/questions/' component={MemberAnketaQuestions} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/complete/' component={MemberAnketaNoInterviewComplete} />
          <PrivateRoute accessibleFor='member' exact path='/client/anketa/:anketaID/call_schedulling/' component={MemberInterview} />
          // * Expert
          <PrivateRoute accessibleFor='expert' exact path='/expert/schedule/' component={ExpertSchedule} />
          <PrivateRoute accessibleFor='expert' path='/expert/respondents/' component={ExpertRespondents} />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/preview/' component={ExpertAnketaDescription} />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/intro/' component={ExpertAnketaTableOfContents} />
          <PrivateRoute
            accessibleFor='expert'
            exact
            path='/expert/review/anketa/:anketaID/:anketaAnswersID/questions/:questionIndex/subquestions/:subquestionIndex/'
            component={ExpertAnketaQuestionsEvaluation}
          />
          <PrivateRoute
            accessibleFor='expert'
            exact
            path='/expert/review/anketa/:anketaID/:anketaAnswersID/questions/:questionIndex/'
            component={ExpertAnketaQuestionsEvaluation}
          />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/questions/' component={ExpertAnketaQuestionsEvaluation} />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/review/' component={ExpertAnketaReview} />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/rate/' component={ExpertAnketaGeneralEvaluation} />
          <PrivateRoute accessibleFor='expert' exact path='/expert/review/anketa/:anketaID/:anketaAnswersID/pdf/' component={ExpertMemberReportPDF} />
          {/* // * Admin */}
          <PrivateRoute accessibleFor='admin' exact path='/admin/' component={AdminClients} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/new_client/' component={AdminClientForm} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/' component={AdminClient} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/edit/' component={AdminClientForm} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/reports/' component={AdminReports} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/anketa_list/' component={AdminClientAnketas} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/anketa_questions/:anketaID/:anketaAnswersID/' component={AdminAnketaEvaluation} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/experts/' component={AdminExperts} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/members/' component={AdminClientMembers} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/' component={AdminClientTemplates} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/copy/' component={AdminTemplateCopy} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/new/'>
            <AdminTemplateForm stage={0} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/case/'>
            <AdminTemplateForm stage={1} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/competentions/'>
            <AdminTemplateForm stage={2} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/inquirer/'>
            <AdminTemplateForm stage={3} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/questions/:questionIndex/'>
            <AdminTemplateForm stage={4} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/questions/'>
            <AdminTemplateForm stage={4} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/templates/:anketaID/recommendations/'>
            <AdminTemplateForm stage={5} />
          </PrivateRoute>
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/' component={AdminClientStream} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/experts/' component={AdminExperts} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/members/' component={AdminClientMembers} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/templates/' component={AdminClientTemplates} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/anketa_list/' component={AdminClientAnketas} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/:streamID/anketa_questions/:anketaID/:anketaAnswersID/' component={AdminAnketaEvaluation} />
          {/* // * HTML анкета индивидуальная */}
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/anketa_questions/:anketaID/:anketaAnswersID/html/' component={AdminMemberReportHTML} />
          <PrivateRoute accessibleFor='admin' exact path='/admin/:clientID/anketa_questions/:anketaID/:anketaAnswersID/html/for_hr/' component={AdminMemberReportHTML} />
          <PrivateRoute
            accessibleFor='admin'
            exact
            path='/admin/:clientID/:streamID/anketa_questions/:anketaID/:anketaAnswersID/html/'
            component={AdminMemberReportHTML}
          />
          <PrivateRoute
            accessibleFor='admin'
            exact
            path='/admin/:clientID/:streamID/anketa_questions/:anketaID/:anketaAnswersID/html/for_hr/'
            component={AdminMemberReportHTML}
          />
          {/* // * Клиентский отчёт */}
          <PrivateRoute accessibleFor='admin' exact path='/admin/client_report/html/:addoID/:key/' component={AdminClientReportHTML} />
          <Route exact path='/admin/client_report/pdf_ard/:addoID/:key/' component={AdminClientReportPDF} />
          {/* // * Трайб отчёт */}
          <PrivateRoute accessibleFor='admin' exact path='/admin/tribe_report/html/:addoID/:key/' component={AdminTribeReportHTML} />
          <Route exact path='/admin/tribe_report/pdf_ard/:addoID/:key/' component={AdminTribeReportPDF} />
          {/* // * PDF анкета индивидуальная */}
          <Route exact path='/admin/:clientID/anketa_questions/:anketaID/:anketaAnswersID/pdf/' component={AdminMemberReportPDF} />
          <Route exact path='/admin/:clientID/anketa_questions/:anketaID/:anketaAnswersID/pdf/for_hr/' component={AdminMemberReportPDF} />
          <Route exact path='/admin/:clientID/:streamID/anketa_questions/:anketaID/:anketaAnswersID/pdf/' component={AdminMemberReportPDF} />
          <Route exact path='/admin/:clientID/:streamID/anketa_questions/:anketaID/:anketaAnswersID/pdf/for_hr/' component={AdminMemberReportPDF} />
          {/* // * 500 */}
          <Route exact path='/500/' component={Error500} />
          {/* // * 404 */}
          <Route exact path='/404/' component={NotFound404} />
          <AnimatedRedirect redirectTo='/404/' />
        </Switch>
      </AnimatePresence>
      {_.includes(location.search, 'support') && <SupportModal />}
    </>
  );
}

export default RouterComponent;
