import './AnketaExpertReview.scss';
import 'react-quill/dist/quill.snow.css';

import {useState, useEffect} from 'react';

import ReactQuill from 'react-quill';
import axios from 'axios';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Title} from '../Title/Title';
import {anketa_description_modal_display_toggle, anketaListActiveQuestionChange} from '../../actions';
import {ReactSelect} from '../../components/ReactSelect/ReactSelect';
import {Button} from '../Button/Button';
import {backendURL} from '../../App';
import consoleLog from '../../helpers/consoleLog';
import {InputError} from '../InputError/InputError';

interface AnketaExpertReviewProps {
    anketaQuestionsData: any;
    anketaAnswersData: any;
    anketaQestionsAnswersData: any;
}

export const AnketaExpertReview: React.FC<AnketaExpertReviewProps> = ({anketaQuestionsData, anketaAnswersData, anketaQestionsAnswersData}) => {
    const history = useHistory();
    const modules = {
        toolbar: [['bold'], [{list: 'bullet'}, {list: 'ordered'}]],
    };
    const dispatch = useDispatch();
    const {anketaID} = useParams<{anketaID: string}>();
    const {anketaAnswersID} = useParams<{anketaAnswersID: string}>();
    const [cheatValue, setCheatValue] = useState(-1);
    const [commentForMember, setCommentForMember] = useState('');
    const [commentForHR, setCommentForHR] = useState('');
    const [cheatValueError, setCheatValueError] = useState('');
    const [commentForMemberError, setCommentForMemberError] = useState('');
    const [commentForHRError, setCommentForHRError] = useState('');
    const [unratedAnswersError, setUnratedAnswersError] = useState('');
    const [onSaveSuccessMessage, setOnSaveSuccessMessage] = useState('');
    const [onSubmitSuccessMessage, setOnSubmitSuccessMessage] = useState('');
    const cheatOptions = [
        {value: 0, label: 'Есть подозрение, что участнику помогали'},
        {value: 1, label: 'Участник заполнил анкету самостоятельно'},
    ];

    const onRate = () => {
        history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/`);
    }

    const onSave = () => {
        // * replace(/(<([^>]+)>)/gi, '') - стрипаем все html теги wysiwyg
        if (
            cheatValue === -1 ||
            (!commentForMember.replace(/(<([^>]+)>)/gi, '') && anketaAnswersData.client.anketa_free_comment === true) ||
            !commentForHR.replace(/(<([^>]+)>)/gi, '')
        ) {
            if (cheatValue === -1) {
                setCheatValueError('Поле обязательно для заполнения');
                setTimeout(() => {
                    setCheatValueError('');
                }, 3000);
            }
            if (!commentForMember.replace(/(<([^>]+)>)/gi, '') && anketaAnswersData.client.anketa_free_comment === true) {
                setCommentForMemberError('Поле обязательно для заполнения');
                setTimeout(() => {
                    setCommentForMemberError('');
                }, 3000);
            }
            if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
                setCommentForHRError('Поле обязательно для заполнения');
                setTimeout(() => {
                    setCommentForHRError('');
                }, 3000);
            }
        } else {
            axios({
                method: 'post',
                url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
                headers: {
                    Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                    who_filled: cheatOptions[cheatValue].label,
                    comment_member: commentForMember,
                    comment_hr: commentForHR,
                },
            })
                .then(result => {
                    setOnSaveSuccessMessage('Сохранено');
                    setTimeout(() => {
                        setOnSaveSuccessMessage('');
                    }, 3000);
                    consoleLog('success', 'Comment saved', result);
                })
                .catch(error => {
                    consoleLog('error', 'Comment saved', error);
                });
        }
    };

    const checkQuestionsAnswersForRates = () => {
        let questionsWithoutAnswer = 0;
        anketaQestionsAnswersData.forEach(questionAnswer => {
            if (questionAnswer.rate === null) {
                questionsWithoutAnswer++;
            }
        });
        return questionsWithoutAnswer;
    };

    const onSubmit = () => {
        if (
            cheatValue === -1 ||
            (!commentForMember.replace(/(<([^>]+)>)/gi, '') && anketaAnswersData.client.anketa_free_comment === true) ||
            !commentForHR.replace(/(<([^>]+)>)/gi, '') ||
            checkQuestionsAnswersForRates() > 0
        ) {
            if (cheatValue === -1) {
                setCheatValueError('Поле обязательно для заполнения.');
                setTimeout(() => {
                    setCheatValueError('');
                }, 3000);
            }
            if (!commentForMember.replace(/(<([^>]+)>)/gi, '') && anketaAnswersData.client.anketa_free_comment === true) {
                setCommentForMemberError('Поле обязательно для заполнения.');
                setTimeout(() => {
                    setCommentForMemberError('');
                }, 3000);
            }
            if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
                setCommentForHRError('Поле обязательно для заполнения.');
                setTimeout(() => {
                    setCommentForHRError('');
                }, 3000);
            }
            if (checkQuestionsAnswersForRates() > 0) {
                setUnratedAnswersError('Есть неотрецензированные ответы участника.');
                setTimeout(() => {
                    setUnratedAnswersError('');
                }, 3000);
            }
        } else {
            axios({
                method: 'post',
                url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
                headers: {
                    Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                    who_filled: cheatOptions[cheatValue].label,
                    comment_member: commentForMember,
                    comment_hr: commentForHR,
                    finish: true,
                },
            })
                .then(result => {
                    setOnSubmitSuccessMessage('Отправлено на проверку администратору. Вы будете перенаправлены на страницу списка анкет через 3с.');
                    setTimeout(() => {
                        history.push('/expert/schedule/');
                        setOnSubmitSuccessMessage('');
                    }, 3000);
                    consoleLog('success', 'Anketa submit', result);
                })
                .catch(error => {
                    consoleLog('error', 'Anketa submit', error);
                });
        }
    };

    const onClick = (index) => {
        history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${index + 1}`);
    };

    useEffect(() => {
        anketaAnswersData.comment_member && setCommentForMember(anketaAnswersData.comment_member);
        anketaAnswersData.comment_hr && setCommentForHR(anketaAnswersData.comment_hr);
        anketaAnswersData.who_filled === 'Есть подозрение, что участнику помогали' && setCheatValue(0);
        anketaAnswersData.who_filled === 'Участник заполнил анкету самостоятельно' && setCheatValue(1);
    }, [anketaAnswersData]);

    const getCommentClass = (item) => {
        const comment = anketaQestionsAnswersData.find(answer => answer.question_id === item.id)?.expert_comment
        if (comment && comment !== "") {
            return `rated-3`;
        } else {
            return `rated-0`;
        }
    };

    const getCommentIcon = (item) => {
        const comment = anketaQestionsAnswersData.find(answer => answer.question_id === item.id)?.expert_comment
        if (comment && comment !== "") {
            return `📄`;
        } else {
            return `📄`;
        }
    };

    const getRateClass = (item) => {
        const rate = anketaQestionsAnswersData.find(answer => answer.question_id === item.id)?.rate
        return `rated-${rate}`;
    };

    return (
        <>
            <section className='anketaExpertReview'>
                <div className='fields'>
                    <Title>Оценки по анкете</Title>
                    <div className='review'>
                        <ol>
                            {anketaQuestionsData.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <div className='question'>
                                            <span className='linkQuestion' onClick={() => onClick(index)}>
                                                {item.text}
                                            </span>
                                        </div>
                                        
                                        <div className='points'>
                                            <div className={`progressionPoint ${getRateClass(item)}`}>
                                                {anketaQestionsAnswersData.find(answer => answer.question_id === item.id)?.rate}
                                            </div>
                                            <div className={`progressionPoint ${getCommentClass(item)}`}>
                                                {getCommentIcon(item)}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
                <div className='submit'>
                    <Button type='button' onClick={() => onRate()} width='100%' size='large' color='blue' text='Комментарии к анкете' />
                    <InputError className='success'>{onSaveSuccessMessage}</InputError>
                </div>
            </section>
        </>
    );
};
